import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/Layout"

const TagPage = ({ data }) => {
    return (
        <Layout>
            <h1>Tags</h1>
        </Layout>
    )
}

export default TagPage

export const pageQuery = graphql`
    query TagsQuery {
        site {
            siteMetadata {
                title
            }
        }
    }
`
